import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Privacy and Policy" />
      <section>
        <h2>Disclaimer</h2>
        LEAPSynSCI cannot take responsibility for information found on third
        party websites outside its control. While we attempt to provide links
        only to third-party websites that comply with all applicable laws and
        regulations and our standards, please understand that the content on
        these third-party websites is subject to change without notice to
        LEAPSynSCI. We therefore cannot be responsible for, and accept no
        liability for, any information or opinion contained in any third-party
        website. As soon as we get notice of infringements on those websites we
        will remove the respective link.
      </section>
    </Layout>
  )
}

export default Privacy
